import React from 'react';
import { Toast } from 'react-bootstrap';
import './toastWrapper.css';

interface ToastWrapperProps {
  showToast: boolean;
  message: string;
  setShowToast: (showToast: boolean) => void;
}

const ToastWrapper = ({
  showToast,
  message,
  setShowToast,
}: ToastWrapperProps) => {
  return (
    <Toast show={showToast} onClose={() => setShowToast(false)}>
      <Toast.Header closeVariant="white">
        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
        <strong className="me-auto">{message}</strong>
      </Toast.Header>
    </Toast>
  );
};

export default ToastWrapper;
