import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar/Navbar';
import MainContent from './components/MainContent/MainContent';
import Footer from './components/MainContent/Footer';
import './App.css';
import ToastWrapper from './components/ToastWrapper/ToastWrapper';

const App: React.FC = () => {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 767px)').matches,
  );
  const [isTablet, setIsTablet] = useState(
    window.matchMedia('(max-width: 1199px)').matches,
  );
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 767px)');
    const mediaQueryTablet = window.matchMedia('(max-width: 1199px)');

    const handleResize = (e: MediaQueryListEvent) => {
      setIsMobile(e.matches);
    };

    const handleResizeTablet = (e: MediaQueryListEvent) => {
      setIsTablet(e.matches);
    };

    mediaQuery.addEventListener('change', handleResize);
    mediaQueryTablet.addEventListener('change', handleResizeTablet);
    return () => {
      mediaQuery.removeEventListener('change', handleResize);
      mediaQueryTablet.removeEventListener('change', handleResizeTablet);
    };
  }, []);

  return (
    <div className="App" id="home">
      <ToastWrapper
        showToast={showToast}
        message={toastMessage}
        setShowToast={setShowToast}
      />
      <Navbar isMobile={isMobile} isTablet={isTablet} />
      <MainContent
        isMobile={isMobile}
        isTablet={isTablet}
        setToastMessage={setToastMessage}
        setShowToast={setShowToast}
      />
      <Footer />
    </div>
  );
};

export default App;
