import React, { useState, useEffect, useRef } from 'react';
import Steps from 'rc-steps';
import { Button } from 'react-bootstrap';
import uploadImages from '../../../assets/images/upload-images.png';
import 'rc-steps/assets/index.css';
import './tryItOut.css';

const steps = [
  {
    title: 'Step1',
    description: 'Upload Deficiency Photos',
  },
  {
    title: 'Step2',
    description: 'Select Concerning Level',
  },
  {
    title: 'Step3',
    description: 'AI Analyze',
  },
];

interface TryItOutProps {
  isMobile: boolean;
  isTablet: boolean;
  setToastMessage: (message: string) => void;
  setShowToast: (showToast: boolean) => void;
}

const TryItOut = ({
  isMobile,
  isTablet,
  setToastMessage,
  setShowToast,
}: TryItOutProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [concerningLevel, setConcerningLevel] = useState('');
  const [briefNote, setBriefNote] = useState('');

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleBrowseClick = () => {
    setShowToast(false);
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const imagesArray = Array.from(files).filter((file) =>
        file.type.startsWith('image/'),
      );

      const uploadImagesCount = files.length;

      if (uploadImagesCount > 10) {
        setToastMessage('Maximum 10 photos allowed.');
        setShowToast(true);
        event.target.value = '';
        return;
      }

      setImageFiles((prevFiles) => [...prevFiles, ...imagesArray]);
    }

    // Reset the input value to allow same file selection
    event.target.value = '';
  };

  const direction = isMobile || isTablet ? 'horizontal' : 'vertical';

  /* Drag & Drop */

  const handleFiles = (files: FileList | File[]) => {
    setShowToast(false);

    const imageFiles = Array.from(files).filter((file) =>
      file.type.startsWith('image/'),
    );

    if (imageFiles.length > 10) {
      setToastMessage('Maximum 10 images allowed');
      setShowToast(true);
      return;
    }

    if (imageFiles.length > 0) {
      setImageFiles((prevFiles) => [...prevFiles, ...imageFiles]);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault(); // This is crucial
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.add('dragging');
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.remove('dragging');
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    // Access files through DataTransferItems
    const items = Array.from(e.dataTransfer.items);
    const files: File[] = [];

    items.forEach((item) => {
      if (item.kind === 'file') {
        const file = item.getAsFile();
        if (file) files.push(file);
      }
    });

    if (files.length > 0) {
      handleFiles(files);
    }
  };

  const resetAll = () => {
    setImageFiles([]);
    setCurrentStep(0);
    setConcerningLevel('');
    setBriefNote('');
  };

  const renderInteractionArea = () => {
    if (currentStep === 2) {
      return (
        <div className="preview-area">
          <div className="preview-grid">
            <div>AI Analysis placeholder</div>
          </div>
          <div className="preview-actions">
            <Button
              variant="outline-primary"
              onClick={() => {
                resetAll();
              }}
            >
              Retry
            </Button>
          </div>
        </div>
      );
    }

    if (currentStep === 1) {
      return (
        <div className="preview-area">
          <div className="preview-grid" style={{ display: 'block' }}>
            <div className="concerning-level-wrapper">
              <h6>Concerning Level*</h6>
              <div>
                <input
                  type="radio"
                  value="Maintenance Item"
                  name="concerning-level"
                  onChange={() => {
                    setConcerningLevel('Maintenance Item');
                  }}
                  checked={concerningLevel === 'Maintenance Item'}
                />
                <label className="ms-2" htmlFor="Maintenance Item">
                  Maintenance Item
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  value="Recommendation"
                  name="concerning-level"
                  onChange={() => {
                    setConcerningLevel('Recommendation');
                  }}
                  checked={concerningLevel === 'Recommendation'}
                />
                <label className="ms-2" htmlFor="Recommendation">
                  Recommendation
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  value="Safety Hazard"
                  name="concerning-level"
                  onChange={() => {
                    setConcerningLevel('Safety Hazard');
                  }}
                  checked={concerningLevel === 'Safety Hazard'}
                />
                <label className="ms-2" htmlFor="Safety Hazard">
                  Safety Hazard
                </label>
              </div>
            </div>
            <div className="brief-note">
              <textarea
                placeholder="Brief note - Select from below or type your own (Optional)"
                value={briefNote}
                onChange={(e) => {
                  setBriefNote(e.target.value);
                }}
              />
              {briefNote === '' && (
                <div className="note-options">
                  <span style={{ color: '#343A40' }}>Common Issues: </span>
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      setBriefNote('Structural Concerns');
                    }}
                  >
                    Structural Concerns
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      setBriefNote('Pest');
                    }}
                  >
                    Pest
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      setBriefNote('Poor Ventilation');
                    }}
                  >
                    Poor Ventilation
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      setBriefNote('Leaking');
                    }}
                  >
                    Leaking
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="preview-actions">
            <Button
              variant="outline-primary"
              onClick={() => {
                resetAll();
              }}
            >
              Retry
            </Button>
            <Button
              className="analyze-button"
              variant="primary"
              onClick={() => setCurrentStep(2)}
              disabled={concerningLevel === ''}
            >
              AI Analyze
            </Button>
          </div>
        </div>
      );
    }

    if (currentStep === 0 && imageFiles.length === 0) {
      return (
        <div
          className="upload-area"
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div className="upload-icon">
            <img src={uploadImages} alt="Upload" width={48} />
          </div>
          <h3>Drag & Drop Photos (Max 10)</h3>
          <p>or</p>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept="image/*"
            multiple
            style={{ display: 'none' }}
          />
          <Button
            variant="primary"
            className="upload-button"
            onClick={handleBrowseClick}
          >
            Browse to Upload
          </Button>
        </div>
      );
    }

    return (
      <div className="preview-area">
        <div className="preview-grid">
          {imageFiles.map((file, index) => {
            return (
              <div key={index} className="preview-item">
                <img
                  src={URL.createObjectURL(file)}
                  alt={`Preview ${index + 1}`}
                  className="preview-image"
                />
              </div>
            );
          })}
        </div>
        <div className="preview-actions">
          <Button
            variant="outline-primary"
            onClick={() => {
              resetAll();
            }}
          >
            Retry
          </Button>
          <Button variant="primary" onClick={() => setCurrentStep(1)}>
            Continue
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="try-it-out-container">
      <div className="steps-section">
        <Steps current={currentStep} direction={direction} items={steps} />
        <div className="note-text">*Add note to enhance accuracy</div>
      </div>
      <div className="interaction-section">{renderInteractionArea()}</div>
    </div>
  );
};

export default TryItOut;
