import React, { useState } from 'react';
import './navbar.css';
import {
  Navbar as BsNavbar,
  Nav,
  NavDropdown,
  Button,
  Col,
  Container,
} from 'react-bootstrap';
import LogoText from '../../assets/images/logo-with-text-64px.png';
import LogoIcon from '../../assets/images/logo-simple-32px.png';

interface NavbarProps {
  isMobile: boolean;
  isTablet: boolean;
}

const Navbar = ({ isMobile }: NavbarProps) => {
  const [expanded, setExpanded] = useState(false);

  const features = [
    { title: 'AI Issue Analysis', href: '#ai-analysis' },
    { title: 'Seamless Note-Taking', href: '#note-taking' },
    { title: 'Annotated Photo Detection', href: '#photo-detection' },
    { title: 'Real Time Report Building', href: '#report-building' },
    { title: 'Smart Template', href: '#smart-template' },
    { title: 'Sample Report', href: '#sample-report' },
  ];

  return (
    <BsNavbar
      expand="md"
      className="navbar"
      fixed="top"
      expanded={expanded}
      onToggle={setExpanded}
    >
      <Container className="d-flex justify-content-between align-items-center">
        <BsNavbar.Brand href="#home" className="navbar-brand-wrapper">
          <picture>
            <source media="(max-width: 1199px)" srcSet={LogoIcon} />
            <img src={LogoText} alt="Morph" height={32} />
          </picture>
        </BsNavbar.Brand>
        <BsNavbar.Toggle aria-controls="basic-navbar-nav" className="ms-2" />

        <div className="navbar-inner">
          {/* Main navigation container */}
          <Col xl={6} sm={8} className="main-nav-container">
            <BsNavbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto" onSelect={() => setExpanded(false)}>
                <NavDropdown
                  title="Features"
                  id="features-dropdown"
                  show={isMobile || undefined}
                >
                  {features.map((feature) => (
                    <NavDropdown.Item key={feature.href} href={feature.href}>
                      {feature.title}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
                <Nav.Link href="#pricing">Pricing</Nav.Link>
                <Nav.Link href="#about">About</Nav.Link>
                <Nav.Link
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  href="mailto:help@morph-technologies.com"
                >
                  Contact
                </Nav.Link>
              </Nav>
            </BsNavbar.Collapse>
          </Col>
          <Col
            xl={6}
            sm={4}
            className={`join-waitlist-btn-wrapper ${expanded ? 'show' : ''}`}
          >
            <Nav.Link href="#join-waitlist">
              <Button
                variant="primary"
                className="join-waitlist-btn"
                onClick={() => setExpanded(false)}
              >
                Join Waitlist
              </Button>
            </Nav.Link>
          </Col>
        </div>
      </Container>
    </BsNavbar>
  );
};

export default Navbar;
