import HeroSection from './HeroSection/HeroSection';
import Features from './Features/Features';
import Pricing from './Pricing/Pricing';
import AboutUs from './AboutUs/AboutUs';
import JoinWaitlist from './JoinWaitlist/JoinWaitlist';
import BlueCurve from '../../assets/images/blue-curve-2x.png';

import React from 'react';

interface MainContentProps {
  isMobile: boolean;
  isTablet: boolean;
  setToastMessage: (message: string) => void;
  setShowToast: (showToast: boolean) => void;
}

const MainContent: React.FC<MainContentProps> = ({
  isMobile,
  isTablet,
  setToastMessage,
  setShowToast,
}) => {
  return (
    <main className="main-content">
      <HeroSection />
      <div className="blue-curve-wrapper">
        <img src={BlueCurve} alt="Decorative blue curve" />
      </div>
      <Features
        isMobile={isMobile}
        isTablet={isTablet}
        setToastMessage={setToastMessage}
        setShowToast={setShowToast}
      />
      <div className="blue-curve-wrapper">
        <img src={BlueCurve} alt="Decorative blue curve" />
      </div>
      <Pricing />
      <AboutUs />
      <JoinWaitlist />
    </main>
  );
};

export default MainContent;
